import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-import-manual-forecast" }, [_c("div", { staticClass: "mx-5 mt-5" }, [_c("a-row", { attrs: { "gutter": 24, "type": "flex", "jusitfy": "between" } }, [_c("a-col", { attrs: { "lg": 24, "xl": 8 } }, [_c("a-card", [_c("div", { staticClass: "d-flex flex-column h-100" }, [_c("div", { staticClass: "ant-card-body__content" }, [_c("h3", { staticClass: "import-title mb-3" }, [_vm._v("Manual Forecast")]), _c("div", { staticClass: "manual-forcast-content" }, [_vm._v(" Use this page to create a Manual Forecast - a forecast not loaded automatically from another source. Overlays can be loaded via an Access database template in the location specified. If you don't have a copy of the template you can download it here. ")])]), _c("a-divider"), _c("a-row", { staticClass: "ant-card-body__btn", attrs: { "type": "flex", "justify": "end" } }, [_c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onDownloadClick } }, [_vm._v("Download")])], 1)], 1)])], 1), _c("a-col", { staticClass: "kh-import-manual-info", attrs: { "lg": 24, "xl": 16 } }, [_c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    var reset = ref.reset;
    return [_c("a-card", [_c("div", { staticClass: "ant-card-body__content" }, [_c("div", { staticClass: "manual-forcast-content" }, [_vm._v(" The uploaded Overlays will be combined with the data in DGP_XXSalesForecastSnapshotT to create the ManualForecast. You can use the template to review the data in DGP_XXSalesForecastSnapshotT. ")]), _c("a-row", { staticClass: "mt-2", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 8 } }, [_c("select-input", { attrs: { "label": "Country", "placeholder": "Select Country", "data-source": _vm.countries, "source": "country", "source-label": _vm.generateCountryLabel, "value": _vm.manualForecast.country, "rules": "required", "disabled": _vm.isImporting, "default-active-first-option": "" }, on: { "change": _vm.onCountryChange } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("select-input", { attrs: { "label": "Version", "placeholder": "Select Version", "reference": "web-analytics.common.dgp-version-manuals", "source": "versionDesc", "value": _vm.manualForecast.versionDesc, "disabled": _vm.isImporting, "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.storeValue("versionDesc", $event);
    } } })], 1), _c("a-col", { attrs: { "span": 8 } }, [_c("select-input", { attrs: { "label": "Fin Year", "placeholder": "Select Fin Year", "reference": "web-analytics.common.dgp-fin-years-current-nexts", "source": "finYear", "value": _vm.manualForecast.finYear, "disabled": _vm.isImporting, "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.storeValue("finYear", $event);
    } } })], 1)], 1), _c("a-row", { staticClass: "mt-2", attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 7 } }, [_c("text-input", { attrs: { "form-item": "", "label": "File name", "value": _vm.manualForecast.fileName, "loading": _vm.isFetchingFile, "disabled": true } })], 1), _c("a-col", { attrs: { "span": 17 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Location", "value": _vm.manualForecast.userLocation, "loading": _vm.isFetchingFile, "disabled": true } })], 1)], 1)], 1), _c("a-divider"), _c("a-row", { staticClass: "ant-card-body__btn", attrs: { "type": "flex", "justify": "end" } }, [_c("a-button", { attrs: { "type": "primary", "loading": _vm.isImporting }, on: { "click": function($event) {
      handleSubmit(_vm.onImportClick(reset));
    } } }, [_vm._v("Import")])], 1)], 1)];
  } }]) })], 1)], 1)], 1)]);
};
var staticRenderFns$1 = [];
var ListImportManualForecast_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".list-import-manual-forecast .ant-col:nth-child(1) .ant-card-body__content[data-v-28ecc21e]{flex:1}.list-import-manual-forecast .ant-col .ant-card[data-v-28ecc21e]{height:100%}.list-import-manual-forecast .ant-col .ant-card[data-v-28ecc21e] .ant-card-body{height:100%;padding:0}.list-import-manual-forecast .ant-col .ant-card[data-v-28ecc21e] .ant-card-body .ant-card-body__content{padding:24px 24px 8px}.list-import-manual-forecast .ant-col .ant-card[data-v-28ecc21e] .ant-card-body .ant-card-body__btn{padding:16px 24px 24px}.list-import-manual-forecast .card[data-v-28ecc21e]{background:#ffffff;border:1px solid #eaedf0}.list-import-manual-forecast .manual-forcast-content[data-v-28ecc21e]{font-size:14px;font-style:normal;color:#272d35}.list-import-manual-forecast .ant-divider[data-v-28ecc21e]{margin:0}\n")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const COUNTRY_NAME = {
  AU: "Australia",
  NZ: "New Zealand"
};
const __vue2_script$1 = {
  name: "ListImportManualForecast",
  inject: ["resourceProps"],
  data() {
    const [forecastProps, countryProps] = this.resourceProps;
    return {
      forecastProps,
      countryProps,
      manualForecast: {},
      isImporting: false,
      isFetchingFile: false
    };
  },
  computed: {
    countries() {
      return this.countryProps.crud.getList().slice(0, 2).map((country) => ({
        ...country,
        countryName: COUNTRY_NAME[country.country]
      }));
    }
  },
  created() {
    this.countryProps.crud.fetchList();
  },
  methods: {
    generateCountryLabel(option) {
      return `${option.country} - ${option.countryName}`;
    },
    storeValue(resourceKey, value) {
      this.manualForecast = {
        ...this.manualForecast,
        [resourceKey]: value
      };
      this.forecastProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    onCountryChange(value) {
      if (value) {
        this.isFetchingFile = true;
        this.axios.get(`${apiUrl$1}/web-analytics/import-manual-forecasts?country=${value}`).then((res) => {
          const { userLocation, fileName } = res.data[0];
          this.storeValue("country", value);
          this.storeValue("userLocation", userLocation);
          this.storeValue("fileName", fileName);
        }).catch((err) => this.$notification.error({
          message: this._.get(err, "response.data.message") || "Errors encountered!"
        })).finally(() => this.isFetchingFile = false);
      }
    },
    onImportClick(reset) {
      if (!this.manualForecast.country)
        return;
      this.isImporting = true;
      this.forecastProps.crud.submitEntity("create").then(() => {
        reset();
        this.isImporting = false;
      });
    },
    onDownloadClick() {
      window.open(`${apiUrl$1}/web-analytics/Templates/ManualForecast.accdb`, "_blank");
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "28ecc21e", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListImportManualForecast = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-import-manual-forcast" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": [
    "web-analytics.import-manual-forecasts",
    "web-analytics.common.countries",
    "web-analytics.common.dgp-version-manuals",
    "web-analytics.common.dgp-fin-years-current-nexts"
  ], "resource-id-name": ["", "country", "versionDesc", "finYear"], "api-url": _vm.apiUrl } }, [_c("list-import-manual-forecast")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListImportManualForecast
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "profitability",
          title: "Profitability",
          path: ""
        },
        {
          key: "import-manual-forecast",
          title: "Import Manual Forecast",
          path: "/web-analytics/import-manual-forecast"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
